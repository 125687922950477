<!--Banner Start-->
<div class="privacy">
    <div class="row mx-0 h-100 justify-content-center align-items-center">
      <div class="col-md-12 col-lg-7 col-xl-6 text-center banner">
        <h1 data-aos="fade-up" data-aos-duration="1000">Privacy Policy

        </h1>

      </div>
    </div>
  </div>
  <!--Banner End-->

  <!--Section Category Start-->
  <div class="privacy_body">
    <div class="container">
      <p class="px-0">
        This privacy policy has been compiled to better serve those who are concerned with how their ‘Personally Identifiable Information’ (PII) is being used online. PII, as described in US privacy law and information security, is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website.
      </p>
      
      <h4>What personal information do we collect from the people that visit our website or app?</h4>
      <p>When contacting us or registering on our site, as appropriate, you may be asked to enter your name, email address, phone number or other details to help you with your experience.</p>

      <h4>When do we collect information?</h4>
      <p>We collect information from you when you fill out a form or enter information on our site.</p>

      <h4>How do we use your information?</h4>
      <p>We may use the information we collect from you when you register, make a purchase, sign up for our newsletter, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways:</p>
      <ul>
          <li>To send periodic emails regarding your order or other products and services.</li>
          <li>To follow up with them after correspondence (live chat, email or phone inquiries).</li>
      </ul>

      <h4>How do we protect your information?</h4>
      <p>We do not use vulnerability scanning and/or scanning to PCI standards. We only provide articles and information. We never ask for credit card numbers. We do not use Malware Scanning. Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential. In addition, all sensitive/credit information you supply is encrypted via Secure Socket Layer (SSL) technology. We implement a variety of security measures when a user enters, submits, or accesses their information to maintain the safety of your personal information. All transactions are processed through a gateway provider and are not stored or processed on our servers.</p>

      <h4>Do we use ‘cookies’?</h4>
      <p>Yes. Cookies are small files that a site or its service provider transfers to your computer’s hard drive through your Web browser (if you allow) that enables the site’s or service provider’s systems to recognize your browser and capture and remember certain information. For instance, we use cookies to help us remember and process the items in your shopping cart. They are also used to help us understand your preferences based on previous or current site activity, which enables us to provide you with improved services. We also use cookies to help us compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future. We use cookies to:</p>
      <ul>
          <li>Keep track of advertisements.</li>
          <li>Compile aggregate data about site traffic and site interactions in order to offer better site experiences and tools in the future. We may also use trusted third-party services that track this information on our behalf.</li>
      </ul>
      <p>You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser settings. Since the browser is a little different, look at your browser’s Help Menu to learn the correct way to modify your cookies. If you turn cookies off, some features will be disabled. It won’t affect the user’s experience that makes your site experience more efficient and may not function properly. However, you will still be able to place orders.</p>

      <h4>Third-party disclosure</h4>
      <p>We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information.</p>

      <h4>Third-party links</h4>
      <p>We do not include or offer third-party products or services on our website.</p>

      <h4>Google</h4>
      <p>Google’s advertising requirements can be summed up by Google’s Advertising Principles. They are put in place to provide a positive experience for users. <a class="link" href="https://support.google.com/adwordspolicy/answer/1316548?hl=en" target="_blank">https://support.google.com/adwordspolicy/answer/1316548?hl=en</a> We use Google AdSense Advertising on our website. Google, as a third-party vendor, uses cookies to serve ads on our site. Google’s use of the DART cookie enables it to serve ads to our users based on previous visits to our site and other sites on the Internet. Users may opt-out of the use of the DART cookie by visiting the Google Ad and Content Network privacy policy. We have implemented the following:</p>

      <ul>
          <li>Remarketing with Google AdSense</li>
          <li>Demographics and Interests Reporting</li>
      </ul>
      <p>We, along with third-party vendors such as Google use first-party cookies (such as the Google Analytics cookies) and third-party cookies (such as the DoubleClick cookie) or other third-party identifiers together to compile data regarding user interactions with ad impressions and other ad service functions as they relate to our website. Opting out:Users can set preferences for how Google advertises to you using the Google Ad Settings page. Alternatively, you can opt out by visiting the Network Advertising Initiative Opt Out page or by using the Google Analytics Opt Out Browser add on.</p>

      <h4>California Online Privacy Protection Act</h4>
      <p>
          CalOPPA is the first state law in the nation to require commercial websites and online services to post a privacy policy. The law’s reach stretches well beyond California to require any person or company in the United States (and conceivably the world) that operates websites collecting Personally Identifiable Information from California consumers to post a conspicuous privacy policy on its website stating exactly the information being collected and those individuals or companies with whom it is being shared. – See more at: <a class="link" href="https://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf" target="_blank">https://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf</a> According to CalOPPA, we agree to the following: Users can visit our site anonymously. Once this privacy policy is created, we will add a link to it on our home page or as a minimum, on the first significant page after entering our website. Our Privacy Policy link includes the word ‘Privacy’ and can easily be found on the page specified above. You will be notified of any Privacy Policy changes:
      </p>

      <ul>
          <li>On our Privacy Policy Page</li>
      </ul>

      <p>Can change your personal information:</p>
      <ul>
          <li>By emailing us</li>
          <li>By calling us</li>
      </ul>

      <p>How does our site handle Do Not Track signals? We honor Do Not Track signals and Do Not Track, plant cookies, or use advertising when a Do Not Track (DNT) browser mechanism is in place. Does our site allow third-party behavioral tracking? It’s also important to note that we allow third-party behavioral tracking.</p>

      <h4>COPPA (Children Online Privacy Protection Act)</h4>
      <p>When it comes to the collection of personal information from children under the age of 13 years old, the Children’s Online Privacy Protection Act (COPPA) puts parents in control. The Federal Trade Commission, United States’ consumer protection agency, enforces the COPPA Rule, which spells out what operators of websites and online services must do to protect children’s privacy and safety online. We do not specifically market to children under the age of 13 years old. Fair Information Practices The Fair Information Practices Principles form the backbone of privacy law in the United States and the concepts they include have played a significant role in the development of data protection laws around the globe. Understanding the Fair Information Practice Principles and how they should be implemented is critical to comply with the various privacy laws that protect personal information. In order to be in line with Fair Information Practices we will take the following responsive action, should a data breach occur: We will notify you via email within 7 business days We also agree to the Individual Redress Principle which requires that individuals have the right to legally pursue enforceable rights against data collectors and processors who fail to adhere to the law. This principle requires not only that individuals have enforceable rights against data users, but also that individuals have recourse to courts or government agencies to investigate and/or prosecute non-compliance by data processors.</p>

      <h4>CAN-SPAM Act</h4>
      <p>
          The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for commercial messages, gives recipients the right to have emails stopped from being sent to them, and spells out tough penalties for violations. We collect your email address in order to:
      </p>
      <ul>
          <li>Send information, respond to inquiries, and/or other requests or questions.</li>
          <li>Market to our mailing list or continue to send emails to our clients after the original transaction has occurred.</li>
      </ul>
      <p>To be in accordance with CAN-SPAM, we agree to the following:</p>
      <ul>
          <li>Not use false or misleading subjects or email addresses.</li>
          <li>Identify the message as an advertisement in some reasonable way.</li>
          <li>Include the physical address of our business or site headquarters.</li>
          <li>Monitor third-party email marketing services for compliance, if one is used.</li>
          <li>Honor opt-out/unsubscribe requests quickly.</li>
          <li>Allow users to unsubscribe by using the link at the bottom of each email. If at any time you would like to unsubscribe from receiving future emails, you can email us.</li>
          <li>Follow the instructions at the bottom of each email and we will promptly remove you from ALL correspondence.</li>
      </ul>

      <h4>Shipping Policy</h4>
      <p>Thank you for using our app! Please note that our app exclusively offers digital services and content. We do not involve the shipment of any physical goods or services. As a result, we do not collect or process any shipping-related information, such as physical addresses or delivery preferences.</p>
      <p><strong>Digital Services and Content Delivery:</strong>
      Our app provides digital services and content, which include but are not limited to access to online resources, software, media, and other digital products.</p>
      <p><strong>Data Collection and Privacy:</strong>
      We respect your privacy. Since we do not deal with physical shipments, we do not collect shipping-related data such as addresses or delivery instructions. The personal information we collect and process is limited to what is necessary for providing and improving our digital services, managing user accounts, and processing payments where applicable.</p>
      <p><strong>Compliance:</strong>
      We are committed to complying with applicable data protection regulations concerning the collection, processing, and storage of personal information. For more information on how we handle your data, please refer to our Privacy Policy.</p>
      <p><strong>Contact Us:</strong>
      If you have any questions regarding our shipping policy or how we handle personal data, please contact us at <a href="mailto:info@axldrone.com">info&#64;axldrone.com</a>.</p>

      <h4>Refund Policy</h4>
      <p>All purchases made through our App, including subscriptions, in-app purchases, and one-time purchases, are final and non-refundable. We do not offer refunds or credits for any partially used subscription periods or unused features of the App.</p>
      <p><strong>Exceptions:</strong></p>
      <p>Refunds will only be provided in the following circumstances:</p>
      <ul>
          <li>Duplicate Purchases: If you accidentally purchase the same item or service more than once, please contact us promptly for assistance.</li>
      </ul>
      <p><strong>Contact Us:</strong>
      If you have any questions about our refund policy or need assistance with a duplicate purchase, please contact our customer support team at <a href="mailto:info@axldrone.com">info&#64;axldrone.com</a>. We are here to help resolve any issues you may encounter.</p>


      <h4>Contacting Us</h4>
      <p>
          If there are any questions regarding this privacy policy, you may contact us using the information below.
      </p>
      

      <div class="address_privacy mt-4">
        <h6>axldrone India Pvt Ltd</h6>
        <p>MEDA Building, Kakkanad</p>
        <p>Ernakulam, Kerala, India - 682037</p>
        <a target="_blank" href="mailto:info@axldrone.com">
          <span>
            <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.125 2.625V11.375C18.125 11.7065 17.9933 12.0245 17.7589 12.2589C17.5245 12.4933 17.2065 12.625 16.875 12.625H3.125C2.79348 12.625 2.47554 12.4933 2.24112 12.2589C2.0067 12.0245 1.875 11.7065 1.875 11.375V2.625M18.125 2.625C18.125 2.29348 17.9933 1.97554 17.7589 1.74112C17.5245 1.5067 17.2065 1.375 16.875 1.375H3.125C2.79348 1.375 2.47554 1.5067 2.24112 1.74112C2.0067 1.97554 1.875 2.29348 1.875 2.625M18.125 2.625L10.7113 7.7575C10.5023 7.90211 10.2541 7.97958 10 7.97958C9.74585 7.97958 9.49774 7.90211 9.28875 7.7575L1.875 2.625"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </span>
          info&#64;axldrone.com
        </a>
      </div>
    </div>

  </div>
  <!--Section Category End-->