<header class="sticky-top">
    <div class="sticky-panel text-nowrap">
        <div class="hstack gap-3">
            <div class=""></div>
            <a [routerLink]="['/axl-store']">
                <!-- <svg width="1rem" height="1rem" viewBox="0 1.5 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.33398 8.0013C3.96732 8.0013 3.67065 8.3013 3.67065 8.66797C3.67065 9.03464 3.96732 9.33464 4.33398 9.33464C4.70065 9.33464 5.00065 9.03464 5.00065 8.66797C5.00065 8.3013 4.70065 8.0013 4.33398 8.0013ZM7.66732 8.0013C7.30065 8.0013 7.00398 8.3013 7.00398 8.66797C7.00398 9.03464 7.30065 9.33464 7.66732 9.33464C8.03398 9.33464 8.33398 9.03464 8.33398 8.66797C8.33398 8.3013 8.03398 8.0013 7.66732 8.0013ZM7.18398 6.33464C7.43398 6.33464 7.65398 6.19797 7.76732 5.9913L8.96065 3.82797C8.98874 3.77744 9.00316 3.72046 9.00246 3.66265C9.00177 3.60485 8.98599 3.54823 8.9567 3.49839C8.9274 3.44855 8.8856 3.40723 8.83543 3.37851C8.78526 3.34979 8.72846 3.33466 8.67065 3.33464H3.73732L3.42398 2.66797H2.33398V3.33464H3.00065L4.20065 5.86464L3.75065 6.67797C3.50732 7.12464 3.82732 7.66797 4.33398 7.66797H8.33398V7.0013H4.33398L4.70065 6.33464H7.18398ZM4.05398 4.0013H8.10398L7.18398 5.66797H4.84398L4.05398 4.0013Z" fill="white"/>
                </svg>  -->
                <i class="bi bi-cart3"></i>
                Store</a>
            <a *ngIf="!authGuard.isLoggedin()" [routerLink]="['/login']">
                <!-- <svg width="20" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.95738 3.11605L8.884 4.04223M8.55325 2.30011L6.04769 4.80567C5.91823 4.93495 5.82993 5.09966 5.79394 5.27905L5.5625 6.43755L6.721 6.20567C6.90037 6.1698 7.06487 6.08186 7.19437 5.95236L9.69994 3.4468C9.77523 3.3715 9.83496 3.28212 9.8757 3.18374C9.91645 3.08537 9.93742 2.97993 9.93742 2.87345C9.93742 2.76697 9.91645 2.66154 9.8757 2.56316C9.83496 2.46479 9.77523 2.3754 9.69994 2.30011C9.62464 2.22482 9.53526 2.16509 9.43688 2.12434C9.33851 2.08359 9.23307 2.06262 9.12659 2.06262C9.02011 2.06262 8.91468 2.08359 8.8163 2.12434C8.71793 2.16509 8.62854 2.22482 8.55325 2.30011Z" stroke="white" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M9.0625 7.3125V8.625C9.0625 8.85706 8.97031 9.07962 8.80622 9.24372C8.64212 9.40781 8.41956 9.5 8.1875 9.5H3.375C3.14294 9.5 2.92038 9.40781 2.75628 9.24372C2.59219 9.07962 2.5 8.85706 2.5 8.625V3.8125C2.5 3.58044 2.59219 3.35788 2.75628 3.19378C2.92038 3.02969 3.14294 2.9375 3.375 2.9375H4.6875" stroke="white" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg> -->
                <i class="bi bi-shield-lock"></i>
                Login/Register</a>
            <a *ngIf="authGuard.getPermission('dashboard')" [routerLink]="['/dashboard']">
                <!-- <svg width="24" height="24" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.17041 2.28125C3.7541 2.28125 1.79541 4.23994 1.79541 6.65625C1.79541 7.80031 2.17013 8.93912 2.88916 9.71875H9.45166C10.1705 8.93912 10.5454 7.80031 10.5454 6.65625C10.5454 4.23994 8.58672 2.28125 6.17041 2.28125Z" stroke="white" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6.17041 8.1875C6.65366 8.1875 7.04541 7.79575 7.04541 7.3125C7.04541 6.82925 6.65366 6.4375 6.17041 6.4375C5.68716 6.4375 5.29541 6.82925 5.29541 7.3125C5.29541 7.79575 5.68716 8.1875 6.17041 8.1875Z" stroke="white" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6.17041 5.125V6.4375M6.17041 3.375V3.8125M2.88916 6.875H3.32666M3.76416 4.6875L4.07347 4.99681M9.01416 6.875H9.45166M8.35791 4.99681L8.66722 4.6875" stroke="white" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg> -->
                <i class="bi bi-speedometer2"></i>
                Dashboard</a>
            <a *ngIf="authGuard.getPermission('partner/dashboard')" [routerLink]="['/partner/dashboard']">
                <!-- <svg width="24" height="24" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.17041 2.28125C3.7541 2.28125 1.79541 4.23994 1.79541 6.65625C1.79541 7.80031 2.17013 8.93912 2.88916 9.71875H9.45166C10.1705 8.93912 10.5454 7.80031 10.5454 6.65625C10.5454 4.23994 8.58672 2.28125 6.17041 2.28125Z" stroke="white" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6.17041 8.1875C6.65366 8.1875 7.04541 7.79575 7.04541 7.3125C7.04541 6.82925 6.65366 6.4375 6.17041 6.4375C5.68716 6.4375 5.29541 6.82925 5.29541 7.3125C5.29541 7.79575 5.68716 8.1875 6.17041 8.1875Z" stroke="white" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6.17041 5.125V6.4375M6.17041 3.375V3.8125M2.88916 6.875H3.32666M3.76416 4.6875L4.07347 4.99681M9.01416 6.875H9.45166M8.35791 4.99681L8.66722 4.6875" stroke="white" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg> -->
                <i class="bi bi-speedometer2"></i>
                Dashboard</a>
            <a *ngIf="authGuard.getPermission('partner/change-password')" [routerLink]="['/partner/change-password']">
                <i class="bi bi-key"></i>
                Change Password</a>
            <span *ngIf="authGuard.isLoggedin() && authGuard.getPermission('profile')" class="hstack">Logged in as <div class="username-highlight">{{authGuard.getLoginName()??'unknown'}}</div></span>
            <a *ngIf="authGuard.isLoggedin()" href="javascript:void(0)" (click)="authGuard.logout()"><i class="bi bi-power"></i> Logout</a>
            <div class=""></div>
        </div>
    </div>
    
    <div class="main-panel text-nowrap">
        <nav class="navbar navbar-expand-xl bg-faded">
            <div class="container-fluid">
                <!-- Brand Logo -->
                <div class="navbar-brand d-flex me-auto gap-3" >
                    <a [routerLink]="['/']" >
                        <img class="primary-logo" src="assets/image/axldrone-logo.svg" alt="axldrone">        
                    </a>
                    <div class="my-auto">
                        <img style="width:auto;height: 40px;padding-bottom: 0.1rem;" src="assets/image/msme.png" alt="registered under msme">  
                    </div>
                    
                </div>
                <!-- Let's Talk - when Menu Collapse -->
                <button [routerLink]="['/lets-talk']" type="button" class="btn btn-secondary button-custom lets-talk-collapsed">Let's Talk</button>
                <!-- navbar toggle button -->
                <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsingNavbar3">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="navbar-collapse collapse justify-content-center" id="collapsingNavbar3"> -->
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsingNavbar3" (click)="toggleMenu()" aria-controls="collapsingNavbar3" aria-expanded="false">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="navbar-collapse collapse justify-content-center" id="collapsingNavbar3" [class.show]="menuOpen">
                    <ul class="navbar-nav">
                        <!-- axl-platform -->
                       
                        <li class="nav-item">
                            <a [routerLink]="['/aakash-platform']" class="nav-link">aakash Platform</a>
                        </li>
                        <!-- innovation -->
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> 
                                Innovations
                            </a>
                            <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarScrollingDropdown">
                                <li *ngFor="let innovation of innovations">
                                    <a [routerLink]="['/innovation',innovation.slug]" class="dropdown-item">{{innovation.title}}</a>
                                </li>
                            </ul>
                        </li>
                        <!-- our product -->
                        <li class="nav-item">
                            <a [routerLink]="['/product']" class="nav-link">Our Products</a>
                        </li>
                        <!-- drone coe -->
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> 
                                Drone COE
                            </a>
                            <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarScrollingDropdown">
                                <li *ngFor="let coe of coes">
                                    <a [routerLink]="['/drone-coe',coe.slug]" class="dropdown-item">{{coe.name}}</a>
                                </li>
                            </ul>
                        </li>
                        <!-- explore -->
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> 
                                Explore 
                            </a>
                            <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarScrollingDropdown">
                                <li *ngIf="newsEventsEnabled"><a [routerLink]="['/news-and-events']" class="dropdown-item">News & Events</a></li>
                                <li><a [routerLink]="['/careers']" class="dropdown-item">Careers</a></li>
                            </ul>
                        </li>
                        <!-- partners -->
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> 
                                Partners 
                            </a>
                            <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarScrollingDropdown">
                                <li><a [routerLink]="['/partner']" class="dropdown-item">Become a Partner</a></li>
                                <li><a [routerLink]="['/partner/login']" class="dropdown-item">Partner Login</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="['/about-us']" class="nav-link">About Us</a>
                        </li>
                    </ul>
                </div>
                <!-- Let's Talk - when Menu Expand -->
                <button [routerLink]="['/lets-talk']" type="button" class="btn btn-secondary button-custom lets-talk-expanded">Let's Talk</button>
            </div>
        </nav>
    </div>

    
</header>
<!-- <script>
    $(document).ready(function () {
        $('.dropdown').hover(function () {
            $(this).addClass('show');
            $(this).find('.dropdown-menu').addClass('show');
        }, function () {
            $(this).removeClass('show');
            $(this).find('.dropdown-menu').removeClass('show');
        });
    });
</script> -->