<!-- <div *ngIf="loading" class="modal-loader">
  <img class="img-loading" src="../../assets/image/axldrone-loading.png" alt="Loading">
</div> -->

<!-- Video outside modal -->
<img *ngIf="selectedImage && !isVideo(selectedImage)" [src]="environment.assetUrl + '/' + selectedImage" (load)="onImageLoad()" style="display: none;" alt="Advertisement">

<!-- Video outside modal -->
<video *ngIf="selectedImage && isVideo(selectedImage)"  [src]="environment.assetUrl + '/' + selectedImage" autoplay muted (loadedmetadata)="onVideoLoad()" style="display: none;">
  <!-- <source  type="video/mp4"> -->
  Your browser does not support the video tag.
</video>

<!-- Image or video inside modal -->
<div *ngIf="showModal && imageLoaded" class="modal-overlay">
  <div class="modal" (click)="onClickOutsideModal($event)">
    <div class="modal-dialog">
      <div class="modal-content">
        <button type="button" class="close" aria-label="Close" (click)="onCloseModal()">
          <svg width="25px" height="25px" viewBox="-6.96 -6.96 37.92 37.92" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff" stroke-width="2.4">
            <g id="SVGRepo_bgCarrier" stroke-width="0">
              <rect x="-6.96" y="-6.96" width="37.92" height="37.92" rx="0" fill="#000000" stroke-width="0"></rect>
            </g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#0F1729"></path>
            </g>
          </svg>
          

        </button>

        <!-- Render media based on type (image or video) -->
        <ng-container *ngIf="selectedImage">
          <ng-container *ngIf="isVideo(selectedImage)">
            <!-- Video player -->
            <video controls autoplay muted disablepictureinpicture controlslist="nodownload noplaybackrate" 
              (loadedmetadata)="onVideoLoad()" [src]="environment.assetUrl + '/' + selectedImage"
              class="modal-media">
              <!-- <source> -->
              Your browser does not support the video tag.
            </video>
          </ng-container>
          <ng-container *ngIf="!isVideo(selectedImage)">
            <!-- Image -->
            <img class="modal-img" [src]="environment.assetUrl + '/' + selectedImage" (load)="onImageLoad()" class="modal-media" alt="Selected Media">
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>
