<div class="carousel" (mouseenter)="pauseAutoSlide()" (mouseleave)="resumeAutoSlide()">
  <div class="carousel-inner-container">
    <button class="carousel-control-prevv" (click)="prevSlide()">&#10094;</button>
    <div class="carousel-inner">
      <img
        [src]="environment.assetUrl + '/' +images[currentIndex]"
        *ngIf="images.length"
        alt="Carousel image"
      />
    </div>
    <button class="carousel-control-nextt" (click)="nextSlide()">&#10095;</button>
  </div>
  <!-- <div class="carousel-indicators">
    <span
      *ngFor="let image of images; let i = index"
      [class.active]="isActive(i)"
      (click)="goToSlide(i)"
    >
    </span>
  </div> -->
</div>
