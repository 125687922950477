<div class="privacy">
  <div class="row mx-0 h-100 justify-content-center align-items-center">
    <div class="col-md-12 col-lg-7 col-xl-6 text-center banner">
      <h1 data-aos="fade-up" data-aos-duration="1000">About Us</h1>
    </div>
  </div>
</div>
<!--Banner End-->

<!--Section Category Start-->
<div class="privacy_body">
  <div class="container">

    
    
     <p>
        axldrone is a trailblazer in the development of remote-controlled drones,
        committed to revolutionizing industries with state-of-the-art technology,
        including an AI platform and an ML-powered engine for advanced aerial
        analysis solutions.
     </p>
     <p>
        axldrone designs and develops intelligent drones in
        both Hovercraft and VTOL category from the ground up that can be
        integrated with a cloud based Aerial Vehicle Management (AVM) system. Each
        Drone and its connected devices and AVM provides comprehensive technology
        solutions for our customers. axldrone is a Pioneer in developing fully
        customized drones with superb control and flexibility to maintain a safe
        environment in and around your premises. axldrone specializes in
        engineering and manufacturing drones for a variety of applications,
        including agriculture, cinematography, surveillance, law enforcement,
        military, and relief operations. 
     </p>
      
      <p>
        Our aakash is an end-to-end Drone Management Platform that gives complete control over your fleet to achieve autonomous inspections, reporting and data collection. This platform is integrated with axl autonomy for data exchange, and the platform provides data processing and analysis tools. It integrates with most ERPs and existing customer systems. Our aakash platform focuses on providing accurate and high-resolution data and in-depth analysis to support that vision, which made us Runners Up in the surveillance category of the 2021 Drone Hackathon organized by the Kerala State Police Department.
      </p>
      <p>
        axl_viman is an iOS based mobile application with a full feature set for managing and directing drone operations. viman is primarily made with the goal of improving the drone-user experience. It is a potent tool for both inexperienced and experienced drone operators which manages project-related data and tracks their flying plans. DGCA regulations and guidelines are integrated into the viman app ensuring the users are up-to-date with current regulations and compliances.
      </p>
      <p>
        Beyond the feature set, Our drones, Our platform and Our support system work together to address your business and other needs as one comprehensive product-service platform.

      </p>
  </div>
</div>
